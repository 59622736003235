import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState, ConfigStoreState } from "../Models/StoresModel";
import { Locales, LanguagesAndCountries } from "../Utils/Locales";
import ConfigInterface from "../Models/ConfigInterface";
import BuybackApiOrder, { Headers } from "@recommerce/buyback-order-sdk";
import DocumentApi, { DocumentApiHeaders } from "@recommerce/common-document-sdk";
import { ActionContext } from "vuex/types/index.js";

type Field = {
  id: string;
  value: string | string[];
};

type BuybackApiOrderHeaders = {
  "Content-Language": string;
  "Accept-Language": string;
} & Headers;

/**
 * @name getters
 */
export const getters: GetterTree<ConfigStoreState, RootState> = {
  apiOrderQueryOptions: (state: ConfigStoreState): object[] => {
    console.log(".:: ConfigStore | Getters | apiOrderQueryOptions ::.");

    const parameters: object[] = [];

    parameters.push({ limit: state.apiResultsPerPage });

    return parameters;
  },

  forcedCustomerField: (state: ConfigStoreState): Field[] => {
    console.log(".:: ConfigStore | Getters | forcedCustomerField ::.");

    const configForcedFields = state.customerDataValidator?.force;
    let toBeReturned: Field[] = [];

    if (configForcedFields && configForcedFields?.length > 0) {
      toBeReturned = configForcedFields;
    }

    return toBeReturned;
  },

  restrictedCustomerField: (state: ConfigStoreState): Field[] => {
    console.log(".:: ConfigStore | Getters | restrictedCustomerField ::.");

    const configExcludedFields = state.customerDataValidator?.exclude;
    let toBeReturned: Field[] = [];

    if (configExcludedFields && configExcludedFields?.length > 0) {
      toBeReturned = configExcludedFields;
    }

    return toBeReturned;
  },

  restrictedCustomerRegex: (state: ConfigStoreState): Field[] => {
    console.log(".:: ConfigStore | Getters | restrictedCustomerRegex ::.");

    const configRegexes = state.customerDataValidator?.regex;
    let toBeReturned: Field[] = [];

    if (configRegexes && configRegexes?.length > 0) {
      toBeReturned = configRegexes;
    }

    return toBeReturned;
  },

  supportUrl: (state: ConfigStoreState): string => {
    console.log(".:: ConfigStore | Getters | supportUrl ::.");

    const locale = state.currentLocale;
    let supportUrl = `https://support-tradein-recommerce.zendesk.com/hc/${locale}/requests/new`;
    const footerLinks = state.footerNavigation.find(list => list.locale === state.currentLocale)?.links;
    const faqUrl = footerLinks?.find(item => item.id === "faq");

    if (faqUrl?.url) {
      supportUrl = `${faqUrl?.url}/requests/new`;
    }

    return supportUrl;
  },

  openingDate: (state: ConfigStoreState): Date => {
    console.log(".:: ConfigStore | Getters | openingDate ::.");

    return state.dateOpening;
  },
};

/**
 * @name mutations
 */
export const mutations: MutationTree<ConfigStoreState> = {
  /**
   * @name setLocale
   * @param {string} locale
   */
  setLocale(state: ConfigStoreState, locale: string) {
    console.log(".:: ConfigStore | Mutations | setLocale ::.");
    state.currentLocale = locale;
  },

  /**
   * @name setConfig
   * @param {ConfigInterface} config
   * @description This configuration is setted at start-up.
   * @see ConfigInterface
   */
  setConfig(state: ConfigStoreState, config: ConfigInterface) {
    console.log(".:: ConfigStore | Mutations | setConfig ::.");

    /**
     * @name instanceId
     * @type string
     */
    state.instanceId = config.instanceId;

    /**
     * @name locales
     * @type string[]
     * @default ['fr']
     */
    state.locales = config.locales ? config.locales : ["fr"];

    /**
     * @name currentLocale
     * @type string
     * @default 'fr'
     */
    state.currentLocale = config.currentLocale ? config.currentLocale : "fr";

    /**
     * @name theme
     * @type string
     * @default 'recommerce'
     */
    state.theme = config.theme ? config.theme : "recommerce";

    /**
     * @name favicon
     * @type string
     */
    state.favicon = config.favicon;

    /**
     * @name apiProductType
     * @type string[]
     * @default ['mobile']
     */
    state.apiProductType = config.apiProductType ? config.apiProductType : ["mobile"];

    /**
     * @name apiResultsPerPage
     * @type string
     * @default '8'
     */
    state.apiResultsPerPage = config.apiResultsPerPage ? config.apiResultsPerPage : "8";

    /**
     * @name appName
     * @type string
     * @default 'Trade-in program'
     */
    state.appName = config.appName ? config.appName : "Trade-in program";

    /**
     * @name country
     * @type string
     * @default 'fr'
     */
    state.country = config.country ? config.country : "fr";

    /**
     * @name footerNavigation
     * @type FooterNavigationItem[]
     * @default []
     */
    state.footerNavigation = config.footerNavigation ? config.footerNavigation : [];

    /**
     * @name canHavePromotions
     * @type boolean
     * @default true
     */
    state.canHavePromotions = typeof config.canHavePromotions !== "undefined" ? config.canHavePromotions : true;

    /**
     * @name carousel
     * @type CarouselElement[]
     * @default []
     */
    state.carousel = config.carousel ? config.carousel : [];

    /**
     * @name dateOpening
     * @type Date
     * @defaut 01/01/1970
     */
    state.dateOpening =
      config.dateOpening && import.meta.env.MODE != "development" ? new Date(config.dateOpening) : new Date("01/01/1970");

    /**
     * @name minimumBuybackAmount
     * @type string
     * @default null
     */
    state.minimumBuybackAmount = config.minimumBuybackAmount ? config.minimumBuybackAmount : null;

    /**
     * @name referrerOnly
     * @type boolean
     * @default false
     * @see BUYBACK-2460
     */
    state.referrerOnly = typeof config.referrerOnly !== "undefined" ? config.referrerOnly : false;

    if (config.referrerOnly) {
      /**
       * @name referrers
       * @type Referrer[]
       */
      state.referrers = config.referrers;

      /**
       * @see BUYBACK-2956
       */
      if (config.defaultReferrer) {
        /**
         * @name defaultReferrer
         * @type Referrer
         */
        state.defaultReferrer = config.defaultReferrer;
      }
    }

    /**
     * @name restrictedIbanZone
     * @type boolean
     * @default false
     */
    state.restrictedIbanZone = typeof config.restrictedIbanZone !== "undefined" ? config.restrictedIbanZone : false;

    /**
     * @name collectBIC
     * @type boolean
     * @default false
     */
    state.collectBIC = typeof config.collectBIC != "undefined" ? config.collectBIC : false;

    /**
     * @name collectIban
     * @type boolean
     * @default true
     */
    state.collectIban = typeof config.collectIban !== "undefined" ? config.collectIban : true;

    /**
     * @name collectAccountNumber
     * @type boolean
     * @default false
     */
    state.collectAccountNumber = typeof config.collectAccountNumber !== "undefined" ? config.collectAccountNumber : false;

    /**
     * @name collectSortCode
     * @type boolean
     * @default false
     */
    state.collectSortCode = typeof config.collectSortCode !== "undefined" ? config.collectSortCode : false;

    /**
     * @name whitelistRoutes
     * @type string[]
     * @default ['all']
     */
    state.whitelistRoutes = config.whitelistRoutes ? config.whitelistRoutes : ["all"];

    /**
     * @name additionalResources
     * @type AdditionalAffiliateResources | null
     * @default null
     */
    state.additionalResources = config.additionalResources ? config.additionalResources : null;

    /**
     * @name process
     * @type string
     * @default "WEB_STANDARD"
     */
    state.process = config.orderAPI && config.orderAPI.process ? config.orderAPI.process : "WEB_STANDARD";

    /**
     * @name customerDataValidator
     */
    const validators = { ...state.customerDataValidator, ...config.customerDataValidator };
    state.customerDataValidator = validators;

    /**
     * @name consents
     */
    const consentsByDefault = [
      {
        type: "checkbox",
        name: "legalAgeCheck",
        label: "terms.legal",
        required: true,
      },

      {
        type: "checkbox",
        name: "cgaCheck",
        label: "terms.read",
        required: true,
      },
    ];

    const affiliateConsentItems = config.consents ? config.consents : [];
    state.consents = affiliateConsentItems.length > 0 ? affiliateConsentItems : consentsByDefault;

    /**
     * @name factoryResetInstructionsUrls
     */
    const defaultFactoryResetInstructions = [
      {
        locale: state.currentLocale,
        links: [
          {
            url: "https://support.apple.com/en-gb/109511",
            id: "apple",
          },
          {
            url: `https://support.google.com/android/answer/7664951?hl=${state.currentLocale}`,
            id: "google",
          },
        ],
      },
    ];

    const factoryResetInstructionsUrls = config.factoryResetInstructionsUrls ? config.factoryResetInstructionsUrls : [];
    state.factoryResetInstructionsUrls =
      factoryResetInstructionsUrls.length > 0 ? factoryResetInstructionsUrls : defaultFactoryResetInstructions;

    /**
     * @see https://recommerce.atlassian.net/browse/BUYBACK-3437
     */
    const selectedApiLanguage: LanguagesAndCountries[] = Locales.filter((language: LanguagesAndCountries) => {
      return language.locale === config.currentLocale;
    });

    const acceptLanguage = `${selectedApiLanguage[0].locale}-${selectedApiLanguage[0].locale.toUpperCase()}`;

    /**
     * @name orderApi
     * @description Buyback Order Sdk settings & instanciation
     */
    const buybackApiHeaders: BuybackApiOrderHeaders = {
      "Content-Type": "application/vnd.api-buyback.v4+json",
      Accept: "application/json, text/javascript, */*; q=0.01",
      Authorization: config.orderAPI.token,
      "Content-Language": config.locales[0],
      "Accept-Language": acceptLanguage,
    };

    let apiUrl = import.meta.env.VITE_BUYBACK_ORDER_API_URL ? import.meta.env.VITE_BUYBACK_ORDER_API_URL : "./";
    state.orderApi = new BuybackApiOrder(apiUrl, buybackApiHeaders);

    /**
     * @name documentApi
     * @description Document Sdk settings & instanciation
     */
    const documentApiHeaders: DocumentApiHeaders = {
      Accept: "application/json, text/javascript, */*; q=0.01",
    };

    apiUrl = import.meta.env.VITE_DOCUMENT_API_URL ? import.meta.env.VITE_DOCUMENT_API_URL : "./";
    state.documentApi = new DocumentApi(apiUrl, documentApiHeaders);
  },
};

/**
 * @name actions
 */
export const actions: ActionTree<ConfigStoreState, RootState> = {
  /**
   * @name setConfig
   * @param {ConfigInterface} config
   */
  setConfig(context: ActionContext<ConfigStoreState, RootState>, config: ConfigInterface) {
    context.commit("setConfig", config);
  },

  /**
   * @name setCurrentLocale
   * @param {string} locale
   */
  setLocale(context: ActionContext<ConfigStoreState, RootState>, locale: string) {
    context.commit("setLocale", locale);
  },
};

/**
 * @name state
 */
export const state: ConfigStoreState = {
  orderApi: null,
} as ConfigStoreState;

const namespaced = true;

export const ConfigStore: Module<ConfigStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
