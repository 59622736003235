/**
 * @description Everything that follows is commented on, and this is deliberate.
 * This is about installing the webgen 1.0 router and routes.
 * The code will be uncommented when the different views are integrated.
 */

import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";
import configRegistry from "@/Config/configRegistry";
import { ErrorResponse } from "@/Models/UtilModel";
// import ConfigInterface from '@/Models/ConfigInterface';
import * as Sentry from "@sentry/vue";
import { Route } from "@sentry/vue/types/router";

/**
 * @name getRoutesName
 * @returns {string[]}
 */
function getRoutesName(): string[] {
  // eslint-disable-next-line
  const rawRoutes = router.getRoutes();

  const routes: string[] = [];

  for (const route of rawRoutes) {
    if (route.name) {
      routes.push(route.name as string);
    }
  }

  return routes;
}

/**
 * Routes
 */
const routes: Array<RouteRecordRaw> = [
  /**
   * @route /
   */
  {
    path: "/",
    redirect: () => {
      return { name: "affiliateNotFound" };
    },
  },

  /**
   * @route /404
   */
  {
    path: "/404",
    name: "affiliateNotFound",
    component: () => import("@/Views/AffiliateNotFound.vue"),
  },

  /**
   * @route /litigation/:orderId
   */
  {
    path: "/litigation/:orderId",
    component: () => import("@/Views/Litigation.vue"),
    beforeEnter: async to => {
      console.log("BeforeEnter : path: /litigation/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("litigation", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /documents/:orderId
   */
  {
    path: "/documents/:orderId",
    component: () => import("@/Views/Documents.vue"),
    beforeEnter: async to => {
      console.log(".:: Router | /documents/:orderId | BeforeEnter ::.");

      try {
        const path = await configRegistry.getPathFromOrderConfig("documents", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /order/:orderId
   */
  {
    path: "/order/:orderId",
    component: () => import("@/Views/OrderDetails.vue"),
    beforeEnter: async to => {
      console.log(".:: Router | /order/:orderId | BeforeEnter ::.");

      try {
        const path = await configRegistry.getPathFromOrderConfig("order", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        const name = error === "404" ? "404" : "affiliateNotFound";
        return { name: name };
      }
    },
  },

  /**
   * @route /identity/:orderId
   */
  {
    path: "/identity/:orderId",
    component: () => import("@/Views/Identity/Identity.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /identity/idcheck/:orderId
   */
  {
    path: "/identity/idcheck/:orderId",
    component: () => import("@/Views/Identity/IdCheck.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/idcheck/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity/idcheck", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /identity/pending/:orderId
   */
  {
    path: "/identity/pending/:orderId",
    component: () => import("@/Views/Identity/IdCheck.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/pending/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity/pending", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /identity/succeed/:orderId
   */
  {
    path: "/identity/succeed/:orderId",
    component: () => import("@/Views/Identity/IdentitySucceed.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/succeed/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity/succeed", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /identity/failed/:orderId
   */
  {
    path: "/identity/failed/:orderId",
    component: () => import("@/Views/Identity/IdentityFailed.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/failed/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity/failed", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /identity/qrcode/:orderId
   */
  {
    path: "/identity/qrcode/:orderId",
    component: () => import("@/Views/Identity/IdentityQrCode.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /identity/qrcode/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("identity/qrcode", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /sendproduct/:orderId
   */
  {
    path: "/sendproduct/:orderId",
    component: () => import("@/Views/SendProduct.vue"),
    beforeEnter: async to => {
      console.log("beforeEnter : path: /sendproduct/:orderId");
      try {
        const path = await configRegistry.getPathFromOrderConfig("sendproduct", to.params["orderId"] as string);
        return { path: path };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    },
  },

  /**
   * @route /:affiliateId
   */
  {
    path: "/:affiliateId",
    component: () => import("@/Views/Root.vue"),
    beforeEnter: async (to: Route) => {
      console.log(".:: Router | Before enter | path: /:affiliateId ::.");

      const affiliateId = to.params["affiliateId"] as string;

      try {
        const affiliateConfig = await configRegistry.getInstanceConfig(affiliateId);
        const defautLocaleForAffiliate = affiliateConfig.locales[0];
        return `/${affiliateConfig.instanceId}/${defautLocaleForAffiliate}`;
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        let message = "";

        if (err.responseText.length > 0) {
          const errorMessage = JSON.parse(err.responseText);
          message = errorMessage.error as string;
        }

        Sentry.captureMessage(`${affiliateId} : ${err.status} | ${message}`, "error");
        return "/404";
      }
    },
  },

  /**
   * @route /:affiliateId/:locale
   */
  {
    path: `/:affiliateId/:locale`,
    component: () => import("@/Views/Root.vue"),
    beforeEnter: async (to: Route) => {
      console.log(".:: Router | BeforeEnter | path: /:affiliateId/:locale ::.");

      /**
       * in url, locale can sometimes be missed, so the :locale is the name in this case.
       */
      const routes = getRoutesName();

      const isRouteNameInLocaleParam = routes.find(name => {
        return name === to.params.locale;
      })
        ? true
        : false;

      if (isRouteNameInLocaleParam) {
        const config = await configRegistry.getInstanceConfig(to.params.affiliateId as string);
        const destinationPath = to.path.replace(`/${to.params.affiliateId}`, "");
        return `/${config.instanceId}/${config.locales[0]}${destinationPath}`;
      }
    },
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/Views/Home.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "documents",
        component: () => import("@/Views/Home.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "documents/:orderId",
        name: "documents",
        component: () => import("@/Views/Documents.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "order",
        component: () => import("@/Views/Home.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "order/:orderId",
        name: "order",
        component: () => import("@/Views/OrderDetails.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "estimate/:productId",
        name: "estimate",
        component: () => import("@/Views/Estimate.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "estimate/:productId/offer",
        name: "recapitulation",
        component: () => import("@/Views/Estimate.vue"),
        meta: { mustBeWhiteListed: true, step: "recapitulation" },
        beforeEnter: (to, from) => {
          if (!from.name) {
            return `/${to.params.affiliateId}/${to.params.locale}`;
          }

          return;
        },
      },

      {
        path: "customer",
        name: "customerdata",
        component: () => import("@/Views/Estimate.vue"),
        meta: { mustBeWhiteListed: true, step: "customerData" },
        beforeEnter: (to, from) => {
          if (!from.name) {
            return `/${to.params.affiliateId}/${to.params.locale}`;
          }
        },
      },

      {
        path: "success",
        name: "success",
        component: () => import("@/Views/Success.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "litigation",
        component: () => import("@/Views/Home.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "litigation/:orderId?",
        name: "litigation",
        component: () => import("@/Views/Litigation.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "*",
        alias: "404",
        name: "notFound",
        component: () => import("@/Views/NotFound.vue"),
      },

      {
        path: "identity/:orderId",
        name: "identity",
        component: () => import("@/Views/Identity/Identity.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "identity/qrcode/:orderId",
        name: "qrcode",
        component: () => import("@/Views/Identity/IdentityQrCode.vue"),
        meta: { mustBeWhiteListed: true, hideHeaderAndFooter: true },
      },

      {
        path: "identity/idcheck/:orderId",
        name: "idcheck",
        component: () => import("@/Views/Identity/IdCheck.vue"),
        meta: { mustBeWhiteListed: true, hideHeaderAndFooter: true },
      },

      {
        path: "identity/pending/:orderId",
        name: "idcheckPending",
        component: () => import("@/Views/Identity/Pending.vue"),
        meta: { mustBeWhiteListed: true, hideHeaderAndFooter: true },
      },

      {
        path: "identity/succeed/:orderId",
        name: "idcheckSucceed",
        component: () => import("@/Views/Identity/IdentitySucceed.vue"),
        meta: { mustBeWhiteListed: true, hideHeaderAndFooter: false },
      },

      {
        path: "identity/failed/:orderId",
        name: "idcheckFailed",
        component: () => import("@/Views/Identity/IdentityFailed.vue"),
        meta: { mustBeWhiteListed: true, hideHeaderAndFooter: false },
      },

      {
        path: "sendproduct/:orderId",
        name: "sendproduct",
        component: () => import("@/Views/SendProduct.vue"),
        meta: { mustBeWhiteListed: true },
      },

      {
        path: "find/:productType",
        name: "findProduct",
        component: () => import("@/Views/FindProduct.vue"),
        meta: { mustBeWhiteListed: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from) => {
  console.log(".:: Router | BeforeEach ::.");
  console.log(from);

  if (to.name !== "affiliateNotFound") {
    if (to.params["affiliateId"]) {
      try {
        const allowedRoutes = await configRegistry.getAllowedRoutes(to.params["affiliateId"] as string);

        /**
         * check if all routes are allowed
         */
        const allIsAllowed = allowedRoutes.filter(allowedRoute => allowedRoute === "all").length > 0 ? true : false;

        console.log(allIsAllowed);

        if (allIsAllowed || to.name === "affiliateNotFound") {
          return;
        }

        /**
         * check if the asked route is allowed
         */
        const isAllowedDestination =
          allowedRoutes.filter(allowedRoute => allowedRoute === to.name).length > 0 ? true : false;

        console.log(isAllowedDestination);

        if (isAllowedDestination) {
          return;
        }

        /**
         * go to 404 if still here.
         */
        return { path: `/${to.params["affiliateId"]}/${to.params["locale"]}/404` };
      } catch (error) {
        return { name: "affiliateNotFound" };
      }
    }
  }
});
export default router;
